.specific .hostel-wrapper {
  position: relative;
}

.hostel-wrapper > .contact-us {
  position: relative;
}
.hostel-wrapper > .contact-us > div {
  background-color: rgb(111, 111, 233);
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
}
.hostel-wrapper > .contact-us > div svg {
  margin-left: 1rem;
  font-size: 1.5rem;
}
.specific .hostel-name {
  margin: 1rem 1rem 1rem 2rem;
  padding: 2rem 0;
}

.specific .hostel-name h2 {
  font-size: 2rem;
  font-weight: 600;
}
.specific .hostel-name > div {
  display: flex;
  font-size: 1.1rem;
  margin-top: 0.4rem;
}
.specific .hostel-name > h2 + div {
  font-style: italic;
}
.specific .hostel-name .location {
  align-items: center;
  margin-top: 0.5rem;
}
.specific .hostel-name .location > svg {
  cursor: pointer;
  color: rgba(255, 68, 0, 1);
}
.specific .hostel-name .location > h3 {
  margin-left: 0.3rem;
  text-decoration: underline;
  cursor: pointer;
}

.specific .image {
  margin-bottom: 2rem;
}
/* .specific .image img {
  height: 30rem;
  width: 70%;
  border-radius: 2rem;
} */

.specific .gender {
  color: rgba(255, 68, 0, 1);
  font-size: 1.1rem;
  font-weight: 600;
}
.rating-gender-container {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rating-gender-container > div:last-child {
  margin-right: 2rem;
}
.rating-gender-container > div:last-child > div {
  display: flex;
  align-items: center;
}
.rating-gender-container > div:last-child > div > button {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  color: rgba(255, 68, 0, 1);
  font-size: 1rem;
  margin-right: 2px;
  background-color: white;
}

.rating-gender-container > div:last-child > div > button > span {
  margin-right: 2px;
  font-size: 16px;
  font-weight: 500;
}
.rating-gender-container > div:last-child > div > button > svg {
  font-size: 1.5rem;
}
.view-review {
  margin-left: 4px;
  cursor: pointer;
}

.specific .owner {
  margin-left: 2rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
.specific .owner > h2 {
  font-weight: 600;
  font-size: 1.2rem;
}
.specific .owner > h2 > span {
  font-size: 1rem;
}
.specific .manager {
  margin-left: 2rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
.specific .manager > h2 {
  font-weight: 600;
  font-size: 1.2rem;
}

.specific .manager > h2 > span {
  font-size: 1rem;
}
.additionalInfo {
  margin: 1rem 2rem;
  border: 2px solid green;
  padding: 0.8rem;
  border-radius: 8px;
  color: green;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
}
.rentCont {
  margin: 1rem 2rem;
}
.rentCont > div {
  margin-bottom: 0.4rem;
}
.specific .common {
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
}
.specific .common > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  gap: 2rem;
}

.specific .common > div > div {
  display: flex;
  align-items: center;
  min-width: 30%;
  padding: 1rem 1rem;
  font-weight: 500;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.071);
  border-radius: 4px;
  background-color: #f8f9fa;
}
.specific .common > div > div svg {
  margin-right: 0.5rem;
  /* color: rgba(255, 68, 0, 1); */
}
.sub-common {
  display: flex;
  margin-right: 0.4rem;
}

.rate-container {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}
.rate-container > button {
  border: none;
  outline: none;
  padding: 0.6rem 1rem;
  font-weight: 500;
  background-color: #218838;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.rating-modal {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.rating-modal > div {
  background-color: white;
  width: 75%;
  height: 320px;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.361);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}
.rating-modal > div > .rating {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rating-modal > div > .rating > svg {
  font-size: 2rem;
  /* color: hsl(9, 100%, 62%); */
  color: rgba(0, 0, 0, 0.557);
  margin: 0 0.7rem;
  cursor: pointer;
}
.rating-modal > div > div:first-child {
  margin-bottom: 0.8rem;
}

.review {
  margin-top: 1.5rem;
}
.review > textarea {
  resize: none;
  height: 70px;
  width: 250px;
  border: 2px solid hsl(9, 100%, 62%);
  outline: none;
  border-radius: 6px;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
}
.rating-button-container {
  margin-top: 1.5rem;
}
.rating-button-container > button {
  border: none;
  outline: none;
  padding: 0.6rem 1rem;
  margin: 0 1rem;
  font-weight: 500;
  background-color: #218838;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.reviews-container {
  width: 100vw;
  height: 500px;
  position: absolute;
  top: 500px;
  z-index: 10000;
  display: flex;
  justify-content: center;
}
.reviews-container > div {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 96vw;
  height: 100%;
  border-radius: 4px;
  overflow: scroll;
}
.reviews-container > div > h2 {
  text-align: center;
  margin-top: 1.8rem;
}
.review-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 1rem 1rem 0 1rem;
}
.review-card > img {
  width: 100px;
}
.review-card > div {
  margin-top: 4px;
}
.review-card > span:last-child {
  margin-bottom: 6px;
}
.cancel-reviews-modal{
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 10000;
  cursor: pointer;
}
.cancel-reviews-modal>svg{
  font-size: 1.4rem;
}
