.form-cont {
  display: flex;
  justify-content: center;
  padding: 2rem;
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.361);
  border-radius: 10px;
  padding: 2rem 0;
}
.form-wrapper > .text {
  margin: 1.4rem 1.2rem 0.2rem 1.2rem;
}

.text > label {
  display: flex;
  flex-direction: column;
}

.text > label span {
  font-size: 1.1rem;
  font-weight: 700;
}

.text > label input {
  border: none;
  outline: none;
  padding-top: 0.9rem;
  padding-bottom: 0.1rem;
  padding-left: 0.3rem;
  border-bottom: 2px solid #78472b;
  font-size: 1rem;
  font-weight: 500;
  color: black;
}
::placeholder {
  color: rgba(0, 0, 0, 0.233);
  font-weight: 600;
}

.states > label > span {
  margin-bottom: 0.7rem;
}
.states > label > select {
  padding: 0.5rem 0.2rem;
  outline: none;
  border: none;
  background-color: #78472b;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.states > label > select option {
  background-color: gray;
}

.available-for {
  font-weight: 700;
  font-size: 1.1rem;
  margin: 1.2rem 1.2rem 0.2rem 1.2rem;
}
#available-for {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.4rem;
}
#available-for > label {
  margin-right: 3rem;
  display: flex;
  align-items: center;
}
#available-for > label > span {
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.7;
  margin-left: 0.5rem;
}
#available-for > label > input:hover {
  cursor: pointer;
}

/* .hostel-type {
  font-weight: 700;
  font-size: 1.1rem;
  margin: 1.2rem 1.2rem 0.2rem 1.2rem;
}
#hostel-type {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.4rem;
}
#hostel-type > label {
  margin-right: 3rem;
  display: flex;
  align-items: center;
}
#hostel-type > label > span {
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.7;
  margin-left: 0.5rem;
}
#hostel-type > label > input:hover {
  cursor: pointer;
} */

.security-deposit, .isPremium {
  margin-left: 2rem;
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
}
.security-deposit > label, .isPremium>label {
  display: flex;
  align-items: center;
}
.security-deposit > label > span, .isPremium > label >span {
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.7;
  margin-left: 0.4rem;
}
.security-deposit > label > input:hover {
  cursor: pointer;
}
.isPremium>label > input:hover{
  cursor: pointer;
}

.occupancy-type,
.available-services,
.available-amenities,
.additional-info,
.additional-note {
  font-weight: 700;
  font-size: 1.1rem;
  margin: 1rem 1.2rem 0.2rem 1.2rem;
}

#occupancy-type {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-right: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}
#occupancy-type > label {
  margin-right: 2.5rem;
  display: flex;
  align-items: center;
}
#occupancy-type > label > span {
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.7;
  margin-left: 0.5rem;
}
#occupancy-type > label > input {
  cursor: pointer;
}

#available-services {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 0.5rem;
}
#available-services > label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
#available-services > label > input {
  margin-right: 0.5rem;
}

#available-services > label > input:hover {
  cursor: pointer;
}
#available-amenities {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 0.5rem;
}
#available-amenities > label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
#available-amenities > label > input {
  margin-right: 0.5rem;
}
#available-amenities > label > input:hover {
  cursor: pointer;
}
#additional-info {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 0.5rem;
}

#additional-info > label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

#additional-info > label > input {
  margin-right: 0.5rem;
}

#additional-info > label > input:hover {
  cursor: pointer;
}
#additional-note {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
}
#additional-note > textarea {
  border: 1.4px solid #78472b;
  border-radius: 6px;
  outline: none;
  font-size: 1rem;
  padding-left: 0.1rem;
  resize: none;
  height: 3rem;
}

input[type="checkbox"] {
  border: 0px;
  width: 1rem;
  height: 1rem;
}

input[type="radio"] {
  border: 0px;
  width: 1rem;
  height: 1rem;
}

.select-image {
  margin: 1rem 1.4rem 1rem 1rem;
}
.select-image > label {
  display: flex;
  flex-direction: column;
}
.select-image > label > span {
  font-size: 1.1rem;
  font-weight: 700;
}

.select-image > label > input {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.5rem;
  border: 2px solid rgba(0, 0, 0, 0.21);
  border-radius: 4px;
}

.button {
  margin: 0.5rem 1.4rem 0.5rem 1.4rem;
}

.button > button {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.6rem 0;
  background-color: #78472b;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 4px;
}

.button > button:hover {
  cursor: pointer;
}


