.update-delete-hostel{
  display: flex;
  justify-content: space-between;
}

.update-delete-hostel button {
  border: none;
  outline: none;
  padding: 0.6rem 0.4rem;
  background-color: #78472b;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.update-delete-hostel button:hover {
  background-color: #218838;
}
#profile-hostel-card {
  cursor: auto;
}

#profile-hostel-card .hostel-image img {
  cursor: pointer;
}
