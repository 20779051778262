.main {
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.main > div {
  padding: 2rem;
}
.main > div > h1 {
  font-weight: 300;
  font-size: calc(1.425rem + 2.1vw);
  line-height: 1.2;
  color: white;
  margin-bottom: 1.6rem;
}
.main > div > h1 > span {
  color: #ff6823;
}
.main > div > p {
  color: white;
  margin-bottom: 1.6rem;
  line-height: 1.4;
}
.main > div > div > button {
  background-color: #ff6823;
  padding: 1rem 2.6rem;
  border: none;
  outline: none;
  color: white;
  border-radius: 6px;
  font-size: 1.1rem;
  cursor: pointer;
}
.mid-section {
  margin-top: 3rem;
  padding: 0 1rem;
}
.mid-section > div > h1 {
  font-size: calc(1.375rem + 1.5vw);
  color: #212529;
  font-weight: 500;
  margin-bottom: 1.6rem;
}
.mid-section > div > h1 > span {
  color: #ff6823;
}
.mid-section > div > p {
  color: #212529;
  margin-bottom: 1.6rem;
  line-height: 1.3;
}
.mid-section > div > .para {
  display: flex;
  margin-bottom: 1rem;
  color: #212529;
  align-items: center;
}
.mid-section > div > .para > svg {
  color: #ff6823;
  margin-right: 0.8rem;
  font-size: 1.2rem;
}
.mid-section > div > div {
  margin-top: 2rem;
}
.mid-section > div > div > button {
  background-color: #ff6823;
  padding: 1rem 2.6rem;
  border: none;
  outline: none;
  color: white;
  border-radius: 6px;
  font-size: 1.1rem;
  cursor: pointer;
}

.hero-banner > div {
  margin-top: 2.5rem;
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
}
.hero-banner > div > img {
  width: 300px;
  position: relative;
  z-index: 2;
}
.hero-banner > div > img::before {
  position: absolute;
  content: "";
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: #b2894e;
  transform: skew(20deg);
  z-index: 2;
}
.section {
  background-color: #ffe8cc;
  padding: 3rem 0;
}
.section > .row {
  display: flex;
  justify-content: space-around;
}

.section > .row > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section > .row > div > h2 {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.service {
  margin-top: 3rem;
}
.service > .row1 > p {
  text-align: center;
  color: hsl(9, 100%, 62%);
  padding: 5px 20px;
  background: hsla(9, 100%, 62%, 0.1);
  border-radius: 50px;
  margin-bottom: 15px;
  width: max-content;
  margin-inline: auto;
}
.service > .row1 > h2 {
  text-align: center;
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 500;
  margin-bottom: 3rem;
}
.service-card {
  padding: 2.5rem 1.4rem;
  margin: 0 1rem 0.2rem 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}
.service-card > .card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-card > .card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1.2rem;
}
.service-card > .card-title > a {
  font-weight: 500;
}
.service-card > .card-title > a:hover,
.service-card > .service-btn > a:hover {
  color: hsl(9, 100%, 62%);
}
.service-card > .card-text {
  text-align: center;
  margin-bottom: 1.4rem;
  line-height: 1.2;
  color: #5d737e;
}
.service-card > .service-btn {
  text-align: center;
  color: #8bb1b1;
  font-size: 14px;
  font-weight: 500;
}
.home-hostels {
  background-color: #ffe8cc;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  padding: 2.4rem 0;
}
.home-hostels > .row > p {
  text-align: center;
  color: hsl(9, 100%, 62%);
  padding: 5px 20px;
  background: hsla(9, 100%, 62%, 0.1);
  border-radius: 50px;
  margin-bottom: 15px;
  width: max-content;
  margin-inline: auto;
}
.home-hostels > .row > h2 {
  text-align: center;
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 500;
  margin-bottom: 1rem;
}

.hostel-card-wrapper > #hostel-card {
  background-color: white;
}

.footer {
  margin-top: 2rem;
}
.footer > .social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.footer > .social-media-icons svg {
  font-size: 1.5rem;
  margin-right: 2rem;
  opacity: 0.8;
}
.footer > .info {
  background-color: #ff6823;
  padding: 2rem 1rem;
}
.footer > .info > div > div {
  text-align: center;
  margin-bottom: 2rem;
  color: white;
}
.footer > .info > div > div > h4 {
  margin-bottom: 1rem;
  font-weight: 600;
}
.footer > .info > div > div > p {
  line-height: 1.5;
  margin-bottom: 1rem;
  font-weight: 300;
}

.footer #contact > p {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer #contact > p > svg {
  margin-right: 1rem;
  font-size: 1.2rem;
  color: gray;
}
.footer #copyright {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.footer #copyright > a {
  font-weight: 600;
}

.footer #copyright > span {
  font-weight: 300;
}
