.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.carousel > .slider{
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
}
.carousel img {
  height: 23rem;
  width: 100%;
  border-radius: 8px;
}
.slider > div:first-child {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 1rem;
  z-index: 2;
  cursor: pointer;
}
.slider > div:last-child {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1rem;
  z-index: 2;
  cursor: pointer;
}
.carousel + div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
.carousel + div > div {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: lightblue;
  margin: 0 0.4rem;
  cursor: pointer;
}
.carousel + div > #selected {
  background-color: rgba(255, 68, 0, 1);
}

.slider > div > svg {
  font-size: 2rem;
  color: white;
}
