.otp-box-container {
  width: 300px;
  margin: 5rem auto;
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  border-radius: 4px;
}
.otp-box-container > h3 {
  font-weight: 400;
  font-size: 14px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.215);
  position: relative;
}
.otp-box-container > h3 > div {
  position: absolute;
  top: 12px;
  left: 2px;
  cursor: pointer;
}
.otp-box-container > h3 > div > svg {
  font-size: 1.4rem;
}
.notification {
  margin-top: 2rem;
}
.notification > div {
  text-align: center;
  font-size: 14px;
  margin-bottom: 0.3rem;
}
.notification > div:last-child {
  font-weight: 500;
}

.otp-number-cont {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 8px;
}
.otp-number-cont > input {
  border: 1.4px solid rgba(8, 8, 8, 0.256);
  outline: none;
  padding: 6px 6px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
}
.resend {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.mobile-login {
  display: flex;
  align-items: center;
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  border-radius: 4px;
}
.mobile-login > span {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 14px;
}

.mobile-login > input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.75rem 0.5rem;
  font-size: 0.9rem;
}
.mobile-login-button {
  margin-top: 1rem;
}
.mobile-login-button > button {
  width: 100%;
  outline: none;
  border: none;
  padding: 0.75rem 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  background-color: #78472b;
  color: white;
  cursor: pointer;
}
.phone-login-form > form {
  width: 300px;
  margin: 7.5rem auto;
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  border-radius: 4px;
  padding: 3rem 1rem 2rem 1rem;
  position: relative;
}
.phone-login-form .back {
  position: absolute;
  top: 10px;
  left: 4px;
  cursor: pointer;
}
.phone-login-form .back > svg {
  font-size: 1.6rem;
}

.verifyOtp {
  margin: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verifyOtp > button {
  width: 50%;
  padding: 12px 8px;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #78472b;
  color: white;
  font-weight: 500;
}
