.contactDetails {
  margin-top: 2rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.contactDetails > h2 {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2rem;
  letter-spacing: 0.02rem;
}
.contactDetails > .contact-number {
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.04rem;
  margin-bottom: 1rem;
}
.contactDetails > .text {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.contactDetails > p {
  background-color: rgb(55, 55, 241);
  color: white;
  text-align: center;
  max-width: 400px;
  border-radius: 6px;
  margin-bottom: 1rem;
}
.contactDetails > p a {
  border: 1px solid white;
  display: inline-block;
  width: 100%;
  padding: 1rem;
}
.contactDetails > .form-btn {
  text-align: center;
  max-width: 400px;
  margin-bottom: 1rem;
}
.contactDetails > .form-btn button {
  background-color: rgb(55, 55, 241);
  border: none;
  outline: none;
  padding: 1rem;
  color: white;
  border-radius: 6px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
}

.contactDetails > .back-to-home {
  text-align: center;
  max-width: 400px;
}
.contactDetails > .back-to-home button {
  color: rgb(55, 55, 241);
  border: none;
  outline: none;
  padding: 1rem;
  border-radius: 6px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  border: 2px solid rgb(55, 55, 241);
}
