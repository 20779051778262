/* body {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  height: 100vh;
} */
.signupCont {
  width: 300px;
  margin: 5.5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  border-radius: 4px;
}

.imageCont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageCont img {
  width: 20%;
}
.imageCont h2 {
  color: #78472b;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.textCont,
.emailCont,
.passwordCont {
  display: flex;
  align-items: center;
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  margin: 0.5rem 1rem;
  border-radius: 4px;
}

.btnCont button:hover {
  background-color: rgba(0, 0, 0, 0.785);
}
.textCont input {
  flex: 1;
  outline: none;
  border: none;
  padding: 0.7rem 0;
  margin-left: 10px;
  font-size: 0.9rem;
  margin-right: 8px;
}

.emailCont input {
  flex: 1;
  outline: none;
  border: none;
  padding: 0.7rem 0;
  margin-left: 10px;
  font-size: 0.9rem;
  margin-right: 8px;
}

.passwordCont input {
  flex: 1;
  outline: none;
  border: none;
  padding: 0.7rem 0;
  margin-left: 10px;
  font-size: 0.9rem;
}
.eye {
  margin-right: 1rem;
}
.forgot {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8rem;
  transition: all 0.1s ease;
}
.forgot:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 255, 0.516);
}
.fileCont {
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  margin: 0.5rem 1rem;
  padding: 0.7rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.fileCont > label {
  color: #78472b;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.fileCont > label > svg {
  font-size: 1.4rem;
  margin-right: 0.6rem;
}

.fileCont > label > span {
  font-weight: 600;
  font-size: 0.9rem;
}
#file {
  display: none;
}

.btnCont {
  display: flex;
  align-items: center;
  margin: 1.5rem 1rem;
  border-radius: 4px;
}
.btnCont button {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.724);
  color: white;
  padding: 0.7rem 0;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
}

.or {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}
.or .one,
.or .three {
  flex: 1;
  border-bottom: 1.4px solid rgba(0, 0, 0, 0.24);
}
.or .two {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.728);
}
.GoogleIconCont {
  display: flex;
  align-items: center;
  margin: 0.8rem 1rem;
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  padding: 0.5rem 0;
  border-radius: 4px;
  cursor: pointer;
}

.GoogleIconCont .g-icon {
  margin-left: 3rem;
  font-size: 1.6rem;
}
.GoogleIconCont div {
  flex: 1;
  text-align: center;
  margin-right: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.831);
}
.hr {
  border-bottom: 1.4px solid rgba(0, 0, 0, 0.24);
  margin-top: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.navCont {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navCont .one {
  font-size: 15px;
}
.navCont .two {
  margin-left: 4px;
  font-size: 15px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.two a {
  color: inherit;
}
.navCont .two:hover {
  text-decoration: underline;
}



.phone-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.8rem 1rem;
  border: 1.6px solid rgba(8, 8, 8, 0.256);
  padding: 0.6rem 0;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.831);
}
.login-signup-text {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 14px;
}
