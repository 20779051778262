.filter-hostels {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0.2rem;
}
.filter-hostels > div {
  position: relative;
}

.filter-hostels > div > .city-wise,
.filter-hostels > div > .locality-wise,
.filter-hostels > div > .gender-wise {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  border: 1px solid #acb5c5;
  padding: 0.4rem 0.6rem;
  border-radius: 2rem;
  cursor: pointer;
  color: #474d59;
  font-weight: 500;
  letter-spacing: -0.4px;
  transition: all 0.3s;
}
.filter-hostels > div > .city-wise:hover,
.filter-hostels > div > .locality-wise:hover,
.filter-hostels > div > .gender-wise:hover {
  color: #3d5fea;
  border: 1px solid #3d5fea;
}
.filter-hostels > div > .city-wise > svg,
.filter-hostels > div > .locality-wise > svg {
  font-size: 1.1rem;
  margin-top: 4px;
}
.filter-hostels > div > .locality-wise + div {
  width: 300px;
  height: 300px;
  background-color: white;
  position: absolute;
  right: 10px;
  top: 38px;
  z-index: 10;
  border-radius: 4px;
  padding: 1rem 0;
  overflow-y: scroll;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.361);
}
#area-wise {
  width: 200px;
  height: max-content;
  background-color: white;
  position: absolute;
  top: 38px;
  z-index: 10;
  border-radius: 4px;
  padding: 1rem 0;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.361);
}

#gender-wise {
  width: 200px;
  height: max-content;
  background-color: white;
  position: absolute;
  top: 38px;
  z-index: 10;
  border-radius: 4px;
  padding: 1rem 0;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.361);
}
.filter-hostels > div > .city-wise + div > div,
.filter-hostels > div > .locality-wise + div > div,
.filter-hostels > div > .gender-wise + div > div {
  padding: 0.7rem 1.2rem;
  margin: 0.2rem 0;
  font-weight: 500;
}
.filter-hostels > div > .city-wise + div > div:hover,
.filter-hostels > div > .locality-wise + div > div:hover,
.filter-hostels > div > .gender-wise + div > div:hover {
  background-color: lightgray;
  cursor: pointer;
}

#selected-city {
  margin-left: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
#selected-city > span {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff6823;
  margin-left: 4px;
}
#selected-location {
  margin-left: 1rem;
}
#selected-location > span {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff6823;
}

.hostel-card-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 0.5rem;
}

.hostel-card {
  width: 290px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.361);
  border-radius: 0.5rem;
  transition: all 0.4s;
  position: relative;
}

.premium {
  position: absolute;
  left: 0;
  top: 24px;
  background-color: #926f34;
  color: white;
  padding: 8px 24px;
  border-radius: 0 12px 12px 0;
}
.hostel-card:hover {
  cursor: pointer;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.361);
}
.hostel-image {
  height: 220px;
}
.hostel-image img {
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}

.hostel-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 20px;
  flex: 1;
}

.hostel-details .hostel-price {
  color: #78472b;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
  width: 70px;
}

.hostel-address-name {
  margin-right: 8px;
}
.hostel-address-name .hostel-name {
  font-size: 13px;
  font-weight: 700;
}
.hostel-address-name .hostel-address {
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.532);
  margin-top: 8px;
}

.load-more {
  margin: 0 1rem 1.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.load-more > button {
  border: none;
  outline: none;
  padding: 0.6rem 1rem;
  background-color: #78472b;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.hostel-card .rating {
  position: absolute;
  right: 0;
  top: 24px;
  background-color: #926f34;
  color: white;
  padding: 6px 12px;
  border-radius: 12px 0 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
