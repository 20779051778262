.profileCont {
  border: 2px solid rgba(0, 0, 0, 0.511);
  border-radius: 10px;
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto 2rem auto;
  padding-bottom: 2rem;
}
.profileCont .img-cont > img {
  width: 200px;
}
   .profileCont .img-cont>.user-profile-pic{
    border-radius: 50%;
    margin: 1rem 0;
   }

.profileCont .about-user {
  margin-top: -1rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
}
.about-user .name {
  margin-bottom: 0.4rem;
}
.profile-hostels {
  width: 50%;
  margin-top: 1rem;
}
.profile-hostels button {
  border: none;
  outline: none;
  width: 90%;
  padding: 0.6rem 0;
  background-color: #78472b;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.3s;
}
.profile-hostels button:hover {
  background-color: #218838;
}
